import {axios} from '@/utils/request'

const api = {
    list: '/statistics.CustomerBill/list',
    cost_list: '/statistics.CustomerBill/getCostList',
    yearMonth: '/statistics.CustomerBill/yearMonth',
    getTotalData: '/statistics.CustomerBill/getTotalData',
    settlement: '/statistics.CustomerBill/settlement',
}

export function list(params) {
    return axios({
        url: api.list,
        method: 'get',
        params
    })
}

export function costList(params) {
    return axios({
        url: api.cost_list,
        method: 'get',
        params
    })
}

export function getTotalData(params) {
    return axios({
        url: api.getTotalData,
        method: 'get',
        params
    })
}

export function yearMonth(params) {
    return axios({
        url: api.yearMonth,
        method: 'get',
        params
    })
}

export function settlement(data) {
    return axios({
        url: api.settlement,
        method: 'post',
        data
    })
}
