<template>
  <a-card :bordered="false">
    <div class="card-title">{{ $route.meta.title }}</div>
    <div class="table-operator">
      <div v-action:export style="margin-bottom: 10px">
        <a-button @click="exportData" icon="export" type="primary">导出为表格</a-button>
      </div>

      状态：
      <a-radio-group
          v-model="queryParam.status"
          button-style="solid"
          @change="handleRefresh(true)"
      >
        <a-radio-button :value="-1">
          全部
        </a-radio-button>
        <a-radio-button :value="0">
          未结算
        </a-radio-button>
        <a-radio-button :value="1">
          已结算
        </a-radio-button>
      </a-radio-group>
      <span style="margin-left: 20px">
      关键词搜索：
      <a-input-search
          enter-button
          allowClear
          style="max-width: 300px; min-width: 150px;"
          v-model="queryParam.customer_name"
          placeholder="请输入客户名称"
          @search="onSearch"
      />
      </span>
      <span style="margin-left: 40px;font-size: 20px">未结算费用：<span style="color: red">¥</span>{{
          total_amount
        }}</span>
    </div>
    <s-table
        ref="table"
        rowKey="payroll_id"
        :loading="isLoading"
        :columns="columns"
        :data="loadData"
        :pageSize="15"
        :scroll="{x:true}"
    >
      <div slot="status" slot-scope="text, item">
        <a-tag v-if="text==1" color="blue">已结算</a-tag>
        <a-tag v-else>未结算</a-tag>
      </div>
      <div slot="net_salary" slot-scope="text, item">
        <span style="color: rgb(208, 5, 5)">¥</span> {{ text }}
      </div>
      <div class="actions" slot="action" slot-scope="text, item">
        <!--        <a-popconfirm-->
        <!--            v-if="item.status==0"-->
        <!--            v-action:settlement-->
        <!--            title="确定结算当前记录？"-->
        <!--            ok-text="确定"-->
        <!--            cancel-text="取消"-->
        <!--        >-->
        <a
            v-if="item.status==0"
            @click="handleSettlement(item)"
            v-action:settlement
        >结算</a>
        <!--        </a-popconfirm>-->
        <a v-action:log @click="handleCommission(item)">账单明细</a>
      </div>
    </s-table>
    <CommissionModel
        ref="CommissionModel"
    />
    <AdditionaModel
        ref="AdditionaModel"
        @handleSubmit="handleRefresh(false)"
    />
    <PayrollDetailsModel
        ref="PayrollDetailsModel"
    />
    <settlement-form-model
        ref="SettlementFormModel"
        @handleSubmit="handleRefresh(false)"
    />
  </a-card>
</template>

<script>
import * as Api from '@/api/statistics/customerBill'
import {STable} from '@/components'
import CommissionModel from "./modules/CommissionModel";
import AdditionaModel from "./modules/AdditionaModel";
import PayrollDetailsModel from "./modules/PayrollDetailsModel";
import SettlementFormModel from "./modules/SettlementForm";

export default {
  name: 'Index',
  components: {
    STable,
    CommissionModel,
    AdditionaModel,
    PayrollDetailsModel,
    SettlementFormModel
  },
  data() {
    return {
      total_amount: 0,
      // 标题
      title: '',

      loading: false,
      // 查询参数
      queryParam: {
        status: -1,
        customer_name: '',
        month_time: '',
      },
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        {
          title: '客户名称',
          width: 200,
          dataIndex: 'customer_name',
        },
        {
          title: '账期',
          width: 100,
          dataIndex: 'month_str',
          scopedSlots: {customRender: 'month_str'}
        },
        {
          width: 200,
          title: '应收客户运输费用（元）',
          dataIndex: 'total_amount',
          scopedSlots: {customRender: 'net_salary'}
        },
        {
          title: '状态',
          width: 100,
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '结算时间',
          width: 100,
          dataIndex: 'settlement_time',
        },
        {
          title: '操作',
          width: 300,
          dataIndex: 'action',
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              this.total_amount = response.data.list.total_amount
              return response.data.list.list
            })
      }
    }
  },
  created() {

  },
  methods: {
    async exportData() {
      const {data: {list}} = await Api.list({...this.queryParam, export: 1})
      window.open(list)
    },
    handleSettlement(item) {
      this.$refs.SettlementFormModel.open(item)
      // const {data, message} = await Api.settlement({bill_id: item.bill_id})
      // this.handleRefresh(false)
      // this.$message.success(message)
    },
    handleCommission(item) {
      this.$refs.CommissionModel.queryParam.bill_id = item.bill_id
      this.$refs.CommissionModel.queryParam.customer_id = item.customer_id
      this.$refs.CommissionModel.queryParam.month_time = item.month_time
      this.$refs.CommissionModel.open(item)
    },

    setMonth(month) {
      this.queryParam.month_time = month
      this.handleRefresh(true)
    },

    /**
     * 查看明细
     */
    handleDetails(item) {
      this.$refs.PayrollDetailsModel.edit(item)
    },

    handleCancel() {
      this.visible = false
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
