<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      :destroyOnClose="true"
      :footer="null"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <div class="table-operator">
        <a-button type="primary" @click="handleAdd">添加费用</a-button>
      </div>
      <s-table
          ref="table"
          rowKey="vr_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
          :scroll="{y:500}"
      >
        <div slot="additional_type" slot-scope="text, item">
          <a-tag color="green" v-if="text==0">增加</a-tag>
          <a-tag color="red" v-else>扣除</a-tag>
        </div>

        <div slot="amount" slot-scope="text, item">
          <span style="color: red">￥</span> {{ text }}
        </div>
        <div class="actions" slot="action" slot-scope="text, item">
          <a v-action:edit @click="handleEdit(item)">编辑</a>
          <a-popconfirm
              v-action:delete
              title="确认删除当前记录？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(item)"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </div>
      </s-table>

      <SaveAdditionaForm
          :payroll_id="queryParam.payroll_id"
          ref="SaveAdditionaForm"
          @handleSubmit="handleRefresh"
      />
    </a-spin>
  </a-modal>
</template>
<script>
import * as Api from '@/api/personnel/additional'
import {STable} from "@/components";
import SaveAdditionaForm from "@/views/statistics/staff/modules/SaveAdditionaForm.vue";

export default {
  name: "ShowToolList",
  components: {
    STable,
    SaveAdditionaForm
  },
  data() {
    return {
      title: '配套工具',

      queryParam: {
        payroll_id: '',
      },
      visible: false,
      loading: false,
      isLoading: false,
      columns: [
        {
          title: 'ID',
          width: 60,
          dataIndex: 'payroll_id',
        },
        {
          title: '费用类型',
          width: 60,
          dataIndex: 'additional_type',
          scopedSlots: {customRender: 'additional_type'}
        },
        {
          title: '费用名称',
          dataIndex: 'additional_name',
          align: 'center',
        },
        {
          title: '金额',
          dataIndex: 'amount',
          align: 'center',
          scopedSlots: {customRender: 'amount'}
        },
        {
          title: '添加时间',
          dataIndex: 'create_time',
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  methods: {
    open({personnel_name,}) {
      this.title = personnel_name + ' - 额外费用'
      this.visible = true
    },
    cancel() {
      this.visible = false
      this.$emit('handleSubmit')
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveAdditionaForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveAdditionaForm.edit(item)
    },

    handleDelete(item) {
      Api.deleted({paId: item['pa_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">

</style>
